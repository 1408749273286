import React from 'react';

const PageExpired = () => {
  return (
    <div className="otherContainer">
      <h1>Sorry, the page you have requested is no longer available. We appreciate you taking your time to participate in our research.</h1>
      <img src="/images/identity_logo_white.png" alt="Citi Logo"/>
    </div>
  );
};

export default PageExpired;
