import React from 'react'

const PageThankYou = () => {
  return (
    <div className="otherContainer">
      <h1>We appreciate you taking your time to participate in our research.</h1>
      <img src="/images/identity_logo_white.png" alt="Citi Logo"/>
    </div>
  )
}

export default PageThankYou