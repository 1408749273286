import React from 'react';

const PageLoading = () => {
  return (
    <div>
    </div>
  );
};

export default PageLoading;
