import React from 'react'

const PageInvalid = () => {
  return (
    <div className="otherContainer">
      <h1>Sorry, the URL you have requested is not available.</h1>
      <img src="/images/identity_logo_white.png" alt="Citi Logo"/>
    </div>
  )
}

export default PageInvalid