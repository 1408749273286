import React from 'react';
import { MdOutlineArrowForwardIos, MdClose, MdOutlineArrowBackIos } from 'react-icons/md';

function Main({ onBack, showBackButton, imageSrc, onNext, showNextButton, showEndButton }) {
  return (
    <>
      <div className='topContainer'>
        <img src='/images/global_header.png' alt='Simulated mobile device email user interface elements' className='images'/>
      </div>
      <div className='pictureContainer'>
        <img src={imageSrc} alt="Email template (3 total)" className='images'/>
      </div>
      <div>
      <img src='/images/global_footer.png' alt='Next page' className='images'/>
      </div>
      <div className="buttonContainer">
      <div className="inner">
          {showBackButton && <button onClick={onBack}><MdOutlineArrowBackIos size="35" color="white" style={{paddingTop: '3px', paddingRight: '3px'}}/></button>}
          {showNextButton && <button onClick={onNext}><MdOutlineArrowForwardIos size="35" color="white" style={{ paddingTop: '3px', paddingLeft: '3px' }} /></button>}
          {showEndButton && <button onClick={onNext}><MdClose size="40" color="white" style={{ paddingTop: '3px', paddingRight: '2px' }} /></button>}
        </div>
      </div>
    </>
  );
}

export default Main;

