import { useState, useEffect } from "react";
import Main from "./components/Main";
import PageExpired from "./components/PageExpired";
import PageInvalid from "./components/PageInvalid";
import PageLoading from "./components/PageLoading";
import PageThankYou from './components/PageThankYou';
import './styles.css';

function App() {

  /*
  --------------------------------------------
  SETUP
  --------------------------------------------
  */

  // set state properties
  const [isLinkInvalid, setIsLinkInvalid] = useState(false);
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSessionEnded, setIsSessionEnded] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // get UUID from querystring
  const querystring = new URLSearchParams(window.location.search);
  const uniqueId = querystring.get('key');

  // flag invalid requests (null UUID)
  if (!isLinkInvalid && uniqueId === null) {
    setIsLinkInvalid(true);
    setIsLoading(false);
  }

  // constants
  const apiBaseUrl = process.env.REACT_APP_EXPRESS_API_URL;
  const postUrl = `${apiBaseUrl}${uniqueId}`;
  const getUrl = `${apiBaseUrl}${uniqueId}`;

  const images = ['REF3_V2_MOB_20230918.2.jpg', 'REF2_V2_MOB_20230918.2.jpg', 'REF5_V1_MOB_20230918.2.jpg'];

  /*
  --------------------------------------------
  CALLBACKS
  --------------------------------------------
  */

  const handleBack = () => {
    const pictureContainer = document.querySelector('.pictureContainer');
    pictureContainer.scrollTop = 0;
    if (currentImageIndex > 0) {
      setCurrentImageIndex(prevIndex => prevIndex - 1);
    }
  };
  
  const handleNext = () => {
    const pictureContainer = document.querySelector('.pictureContainer');
    pictureContainer.scrollTop = 0;
  
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex(prevIndex => prevIndex + 1);
    } else if (currentImageIndex === images.length - 1) {
      // Handle end session logic here
      setIsSessionEnded(true);
      setIsLinkExpired(true);
  
      // Set expiration cookie
      const currentDate = new Date();
      const expirationDate = new Date(currentDate.getTime()); 
      document.cookie = `expiration=${expirationDate.toUTCString()}; path=/`;
  
      // Send POST request to update sessionExpired value on the server
      fetch(postUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ sessionExpired: true })
      });
    }
  };
  

  /*
  --------------------------------------------
  CALLBACKS
  --------------------------------------------
  */

  //checks for link expiration, if 60 min elapsed it shows the link expired page. 
  useEffect(() => {

    // exit if null uniqueId
    if (uniqueId === null) {
      return;
    }

    // Check for expiration cookie
    const expirationCookie = document.cookie
      .split('; ')
      .find(row => row.startsWith('expiration='))
      ?.split('=')[1];

    // console.log('exp_c', expirationCookie);
  
    if (expirationCookie) {
      // Expiration cookie exists, check if it has expired
      const expirationDate = new Date(expirationCookie);
      const currentDate = new Date();
      if (currentDate > expirationDate) {
        // Expiration cookie has expired, set isLinkExpired to true
        setIsLinkExpired(true);
      }
    } else {
      // Expiration cookie does not exist, write it
      const currentDate = new Date();
      const expirationDate = new Date(currentDate.getTime() + 85 * 60 * 1000); // Add 85 minutes to current time
      document.cookie = `expiration=${expirationDate.toUTCString()}; path=/`;
    }

    setIsLoading(false);
  
  }, [uniqueId]);

  // Check if session for uniqueId has expired and update state accordingly
  useEffect(() => {

    // exit if null uniqueId
    if (uniqueId === null) {
      console.log('uniqueId is null, return');
      return;
    }

    fetch(getUrl)
      .then(res => res.json())
      .then(data => {
        setIsLinkExpired(data.sessionExpired);
        setIsLoading(false);
      });

  }, [getUrl, uniqueId]);

  /*
  --------------------------------------------
  VIEWS
  --------------------------------------------
  */

  return (
    <div className="App">
      <div className="container">
        {
        isLoading ? (<PageLoading />) :
        isSessionEnded ? (<PageThankYou />) : 
        isLinkExpired ? (<PageExpired />) : 
        isLinkInvalid ? (<PageInvalid />) : 
        (
        <>
         <Main
              imageSrc={`/images/${images[currentImageIndex]}`}
              showBackButton={currentImageIndex > 0}
              showNextButton={currentImageIndex < images.length - 1}
              showEndButton={currentImageIndex === images.length - 1}
              onBack={handleBack}
              onNext={handleNext}
            />
          </>
        )}
      </div>
    </div>
  );

}

export default App;
